import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/about"

const AboutPage = () => (
  <Layout>
    <Seo title="Unsere Erfahrung" />
    <Hero />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <StaticImage
              src="../images/about.jpg"
              quality={65}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Clavicon Profile"
              placeholder="tracedSVG"
            />
          </div>
          <div className="column is-three-fifths">
            <div className="content">
              <h3 className="mt-0">
              خبرتنا تعني أمنكم
              </h3>              
              <p>
              خبرتنا تعني أمنكم كلافيكون شركة استشارات متخصصة في إدارة الأمن والأزمات. وبفضل خبرتنا الطويلة والتقنية المتقدمة نستطيع تقديم خدمات استشارات أمنية متكاملة لتأمين الشركات والأفراد، وذلك وفقًا للاحتياجات الأمنية لكل شخص على حدة. لقد تعرفنا على موضوع الأمان من زوايا مختلفة، وتمكنا من تطوير قدراتنا في قطاع الأمن الحكومي على مستوى الولايات والمستوى الفيدرالي من ناحية، ومن ذلك مهمات خاصة لسنوات طويلة في مكافحة الجريمة المنظمة والتعامل مع حالات الاختطاف والابتزاز، ومن ناحية أخرى في مجال تأمين كبار الساسة ومجال الجريمة الالكترونية. وأيضًا في مجال الاقتصاد الخاص وتأمين الشركات الرائدة ذات البنية المعقدة، ساهمنا بخبراتنا مُنذ سنوات عديدة على المستويين المحلي والعالمي بشكل مؤثر في بناء وتطوير الإدارة الأمنية. هذه الخبرة مدعومة علميا بشهادات جامعية في مجال الإدارة الأمنية إضافة لشهادات في مجال إدارة المخاطر والأزمات وتأمين المعلومات. المديرين التنفيذيين المتخصصين لديهم أيضا موظفين و مدربين تجارين تحت تصرفهم.
              </p>
              <p>
              وخلاصة القول أننا بفضل خبرتنا نضمن الاحترافية والأمانة والتكتم ونمثل بذلك إضافة عظيمة لأمنك.
              </p>
            </div>
            <div className="columns">
              <div className="column">
                <figure>
                  <StaticImage
                    src="../images/profile.jpg"
                    quality={65}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Clavicon Profile"
                  />
                </figure>
              </div>
              <div className="column is-three-fifths">
                <h5>
                بالنسبة لكلافيكون،لا يعد الأمان منتجا بل قضية.نحن هنا لنرافقك.
                </h5>
                <div className="block">
                كيم شلاتمن
                  <br />
                  المدير العام لكلافيكون
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutPage
